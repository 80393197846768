import {HostData} from './secondary/HostData';
import {TeammateData} from './secondary/person/TeammateData';
import {PersonGuestData} from './secondary/person/PersonGuestData';
import {Photo} from './secondary/Photo';
import {TeammateInvitationDto} from './secondary/person/TeammateInvitationDto';
import {CohostInvitationDto} from './secondary/person/CohostInvitationDto';
import {UserAgentInfo} from './secondary/person/UserAgentInfo';
import {OnBoarding} from './secondary/OnBoarding';
import {Corporate} from './Corporate';
import {SwiklyApiKeys} from './secondary/SwiklyApiKeys';
import {CoHostInCharge} from "./Property";
import {Amount} from "./secondary/Amount";
import {Role} from "./Role";
import {PlatformRole} from "./PlatformRole";

export class Person {
  id: string;

  // SECTION ONBOARDING
  personCategory: string;
  numberOfProperties: number;
  onBoarding: OnBoarding;
  // END SECTION ONBOARDING

  firstName: string;
  lastName: string;
  email: string;
  secondaryEmail: string;

  closed: boolean;
  trialPeriodOver: boolean;
  trialPeriodOverDate: string;
  hasPaymentMethod: boolean;

  fullAddress: string;
  address: string;
  zipCode: string;
  city: string;

  companyName: string;
  companyRegistrationNumber: string;
  vatNumber: string;
  isProvider: boolean;
  vatRate: number;

  languages: string[];
  language: string;
  currency: string;
  country: string;
  pictureUrl: string;
  gender: string;
  birthdate: string;
  phone: string;

  locale: string;
  host: HostData;
  teammate: TeammateData;
  guest: PersonGuestData;
  beta: boolean;

  photos: Photo[];
  pendingInvitations: TeammateInvitationDto[];
  pendingCohostInvitations: CohostInvitationDto[];

  creationTime: string;
  lastSignInTime: string;
  identityProviders: string[];

  talkJsSignature: string;
  propertyCount: number;
  propertyCountAsHost: number;

  lastCheckTime: string;
  userAgents: UserAgentInfo[];

  corporateId: string;
  corporate: Corporate;
  // motherCorporates: Corporate[];
  motherCorporate: Corporate;
  emailVerified: boolean;

  //iglooHomeApiKey: string;

  stripePromotionCode: string;
  failedInvoices: string[];

  totalPendingAmount: Amount;
  pendingInvoices: PendingInvoice[];

  airbnbAccountIds: string[];
  bookingDotComAccountIds: string[];
  VRBOAccountIds: string[];
  beds24AccountIds: string[];
  avantioAccountIds: string[];
  eviivoAccountIds: string[];
  bookingSynchAccountIds: string[];
  smoobuAccountIds: string[];
  giteDeFranceAccountIds: string[];
  septeoAccountIds: string[];
  septeoAgencyIds: string[];
  enovaAgencyIds: string[];

  marketingCampaign: string;

  personAlerts: PersonAlert[];

  //subscriptionForLivretSeul: boolean;

  // subscriptionWithCommitment: boolean;

  allowSendingWhatsApp: boolean;
  allowSendingSMS: boolean;

  // DD 21/02/2022 : MOVED TO Corporate
  // defaultPropertyBehaviour: DefaultPropertyBehaviour;
  // swiklyLinked: boolean;

  btobInfo: BtobInfo;

  registrationCategory: string;
  registrationRentalsNumber: string;
  registrationNotOwner: boolean;

  noEmailNotification: boolean;
  noEmailShown: boolean;

  whatsAppLink: string;
  whatsAppSMSFrom: string;
  influencerDetails: InfluencerDetails;

  corporatePermissions: CorporatePermission[];
  creationSentMessage: string;
  creationMessageTime: string;

  subscriptionName: string;
  secondarySubscriptionName: string;
  registrationHowDoYouKnowUs: string;
  registrationOtherTools: string;
  corporateOwner: boolean;

  corporatesRoles: CorporateRole[];

  taskManagement: PersonTaskManagement = new PersonTaskManagement();

}

export class CorporateRole {
  corporateId: string;
  roleId: string;
}

export class PendingInvoice {
  pendingAmount: Amount;
  invoiceUrl: string;
}

export class BtobInfo {
  companyName: string;
  logoUrl: string;
  websiteUrl: string;
}
export class DefaultPropertyBehaviour {
  needPreCheck: boolean;
  needCleaning: boolean;
  canEarlyCheck: boolean;
  noTimeNeededForCheck: boolean;
  noshowRecommendationOnBooklet: boolean;
  noshowPlatformServicesOnBooklet: boolean;
  noshowHygieneOnBooklet: boolean;
  noshowMatterportOnBooklet: boolean;
  noshowBedsDetail: boolean;
  noshowGuestReview: boolean;
  emailCcs: string;
  emailCopyYaago: boolean;
  coHostInCharge: CoHostInCharge;
  showMeAsHost: boolean;
  showMeAsCoHostInCharge: boolean;
  defaultCheckInTime: string;
  defaultCheckOutTime: string;
  noAudioDisplay: boolean;

  checkInOnline: boolean;
  // mandatorySetupStay: boolean;
  noOnlineCheckinValidation: boolean;
  checkinOnlineWithContract: boolean;
  checkinOnlineWithCgv: boolean;
  onlineCheckinValidationMaxTime: string;
  onlineCheckWithInventoryDocument: boolean;
  checkinOnlineWithTransport: boolean;

  noShowTeammateOnBooklet: boolean;
  noShowChatContact: boolean;
  noShowParticularRequest: boolean;
  lightOnLinePreCheck: boolean;
  overrideWithOtaPictures: boolean;
  overrideWithOtaDescription: boolean;
  disableActionTranslation: boolean;
}
export class PersonAlert {
  alertedTime: string;
  alertCode: string;
  alertType: string;
}
export class InfluencerDetails {
  bio: string;
  socialMediaUrl: string;
  blogUrl: string;
  instaAccount: string;
}

export class CorporatePermission {
  corporateId: string;
  permissions: string[] = [];
  modulesTags: string[] = [];
}
export class PersonTaskManagement {
  taskTypes: PersonTaskType[] = [];
  workingDaysOfWeek: number[] = [];
  startOfDay: string = "09:00";
  endOfDay: string = "18:00";
}
export class PersonTaskType {
  taskId: string;
  taskType: string;
  limitOfTaskItemsPerDay: number  = 0;
}
